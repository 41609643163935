import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { Router } from '@angular/router';


@Injectable()
export class AdminService {
	hasENERNOCProvider = false;
	constructor(private httpClient: HttpClient) {}

	getLoggingInfo(options) {
		return this.httpClient.post('/management/getLoggingInfo', options, {
			observe: "body",
			responseType:"json"
		});
	}

	deleteLog(id, type) {
		if (!confirm('Are you sure you want to proceed with delete?')) {
			return new Observable((observer) => {
    			observer.next(false);
				observer.complete();
			});
		}
		return this.httpClient.post('/management/deleteLog', {id, type}, {
			observe: "body",
			responseType:"json"
		});
	}

	getErrorLog(errorId: number, errorType: string) {
		return this.httpClient.post('/management/getErrorInfo', {id: errorId, logType: errorType}, {
			observe: "body",
			responseType:"json"
		});
	}

	getUploadQueueFaultedRecord(reqId: number, deviceType: string) {
		return this.httpClient.post('/management/getUploadQueueFaultedRecord', {reqId, deviceType}, {
			observe: "body"
		});
	}

	getCronJobsStatus() {
		return this.httpClient.post('/management/getCronJobsStatus', {}, {
			observe: "body"
		});
	}

	addSchedToRun(sched: any) {
		return this.httpClient.post('/management/addSchedToRun', { sched }, {
			observe: "body"
		});
	}

	getBattviewFunctionalTests() {
		return this.httpClient.post('/bfd/getBattviewFunctionalTests', {}, {
			observe: "body"
		});
	}

	getSchedFunctionsStatus() {
		return this.httpClient.post('/management/getSchedFunctionsStatus', {}, {
			observe: "body"
		});
	}

	updateSchedFunctionsStatus(schedFunctions) {
		return this.httpClient.post('/management/updateSchedFunctionsStatus', schedFunctions, {
			observe: "body"
		});
	}

	getSchedFunctionsHistory(options) {
		return this.httpClient.post('/management/getSchedFunctionsHistory', options, {
			observe: "body"
		});
	}

	getOEMs(id:number = 0) {
		return this.httpClient.post('/users/getOEMs', {id}, {
			observe: "body"
		});
	}

	getDealers(id:number = 0, type: string = 'dealer', getACTusers: boolean = false) {
		return this.httpClient.post('/users/getDealers', {id, type, getACTusers}, {
			observe: "body"
		});
	}

	getSmartRebatesSitesInfo() {
		return this.httpClient.post('/customers/getSmartRebatesSitesInfo', {}, {
			observe: "body"
		});
	}

	downloadConsumptionsReport(year: number, quarter: number, siteId: number, selectedStartDate: any) {
		return this.httpClient.post('/reporting/get-sr-consumption-reports', {siteId, year, quarter, selectedStartDate});
	}

	getRoles() {
		return this.httpClient.get('/users/getRoles', {
			observe: "body"
		});
	}

	getDealerUsers(id) {
		return this.httpClient.post('/users/getDealerUsers', {id}, {
			observe: "body"
		});
	}

	addGuestAsUser(user) {
		return this.httpClient.post('/users/addGuestAsUser', user, {
			observe: "body"
		});
	}

	deleteDealerOrOEM(id, type, confirmed) {
		return this.httpClient.post('/users/deleteDealerOrOEM', {id, type, confirmed}, {
			observe: "body"
		});
	}

	updateOEM(oem) {
		return this.httpClient.post('/users/updateOEM', oem, {
			observe: "body"
		});
	}

	updateDealer(oem, type) {
		return this.httpClient.post('/users/updateDealer', {oem, type}, {
			observe: "body"
		});
	}

	getUserNamesByRole(role, id: number = 0) {
		return this.httpClient.post('/users/getUserNamesByRole', {role, id}, {
			observe: "body"
		});
	};

	sendCustomizedEmail(email) {
		return this.httpClient.post('/users/sendCustomizedEmail', email, {
			observe: "body"
		});
	};

	getSiteSubscriptionInvoices(siteID) {
		return this.httpClient.post('/customers/getSiteSubscriptionInvoices', {siteID}, {
			observe: "body"
		});
	};

	getInvoicesMismatchForBattviews() {
		return this.httpClient.post('/customers/getInvoicesMismatchForBattviews', {}, {
			observe: "body"
		});
	};

	updateInvoicesMismatchSubscribers(subscribers) {
		return this.httpClient.post('/customers/updateInvoicesMismatchSubscribers', {subscribers}, {
			observe: "body"
		});
	};

	modifySiteInvoices(invoice, stage) {
		return this.httpClient.post('/customers/modifySiteInvoices', {invoice, stage}, {
			observe: "body"
		});
	};

	getUploadFaildDB() {
		return this.httpClient.post('/admins/getUploadFaildDB', {}, {
			observe: "body",
			responseType:"json"
		});
	}

	getFaultQueueLog(): Observable<any> {
		return this.httpClient.post('/management/getFaultQueueLog', {}, {
			observe: "body",
			responseType:"json"
		});
	}
	
	unlockFaultQueueLog(deviceType, deviceId) {
		return this.httpClient.post('/management/unlockFaultQueueLog', {deviceType, deviceId}, {
			observe: "body",
			responseType:"text"
		});
	}
	
	searchForCustomers(options) {
		return this.httpClient.post('/customers/searchForCustomers', options, {
			observe: "body"
		});
	};

	getCustomers(id: number = 0) {
		return this.httpClient.post('/customers/get/' + id, {}, {
			observe: "body"
		});
	};

	saveCustomer(customer) {
		return this.httpClient.post('/customers/saveCustomer', customer, {
			observe: "body"
		});
	};
	saveUser(user) {
		return this.httpClient.post('/users/saveUser', user, {
			observe: "body"
		});
	};

	getWidgets() {
		return this.httpClient.get('/admins/getSiteWidgets', {
			observe: "body"
		});
	}

	updateWidgets(widgets) {
		return this.httpClient.post('/admins/updateSiteWidgets', widgets, {
			observe: "body"
		});
	}

	saveSite(site) {
		return this.httpClient.post('/customers/saveSite', site, {
			observe: "body"
		});
	}

	deleteCustomerOrSite(customerId, siteId, confirmedField = '') {
		return this.httpClient.post('/customers/deleteCustomerOrSite', {customerId, siteId, confirmedField}, {
			observe: "body"
		});
	}

	getSite(site) {
		return this.httpClient.post('/customers/getSite', site, {
			observe: "body"
		});
	};

	getChargers(charger, options = {}) {
		return this.httpClient.post('/chargers/getChargers', {charger, options}, {
			observe: "body"
		});
	};

	getNotAssignedChargers(options) {
		return this.httpClient.post('/chargers/getNotAssignedChargers', options, {
			observe: "body"
		});
	};

	getUnassignedBattViews(options) {
		return this.httpClient.post('/bfd/getUnassignedBattViews', options, {
			observe: "body"
		});
	};

	getCharger(id, isFreeCharger = false) {
		return this.httpClient.post('/chargers/getCharger', {id, isFreeCharger}, {
			observe: "body"
		});
	}

	getOwnersList(customerid = 0) {
		return this.httpClient.post('/customers/getOwnersList', {customerid}, {
			observe: "body"
		});
	}

	getClientsByNameIndex(index) {
		return this.httpClient.post('/customers/getClientsByNameIndex', {index}, {
			observe: "body"
		});
	}

	getClientSites(customerid) {
		return this.httpClient.post('/customers/getClientSites', {customerid}, {
			observe: "body"
		});
	}

	saveCharger(charger) {
		return this.httpClient.post('/chargers/saveCharger', charger, {
			observe: "body"
		});
	}

	getBattviews(options) {
		return this.httpClient.post('/bfd/getBattviews', options, {
			observe: "body"
		});
	};

	assignBattviewsToEastPenn(ids) {
		return this.httpClient.post('/bfd/assignBattviewsToEastPenn', {ids}, {
			observe: "body"
		});
	};

	getBattview(id, isFreeBattview = false) {
		return this.httpClient.post('/bfd/getBattview', {id, isFreeBattview}, {
			observe: "body"
		});
	}

	saveBattview(battview) {
		return this.httpClient.post('/bfd/saveBattview', battview, {
			observe: "body"
		});
	}

	deleteDevice(id, type) {
		return this.httpClient.post('/management/deleteDevice', {id, type}, {
			observe: "body"
		});
	};

	getDeviceOwnerChangeRequest(ids, type) {
		return this.httpClient.post('/management/getDeviceOwnerChangeRequest', {ids, type}, {
			observe: "body"
		});
	};

	getDeviceOwners(deviceIDs, type) {
		return this.httpClient.post('/customers/getDeviceOwners', {deviceIDs, type}, {
			observe: "body"
		});
	};

	getDeviceDates(deviceIDs, type) {
		return this.httpClient.post('/customers/getDeviceDates', {deviceIDs, type}, {
			observe: "body"
		});
	};

	GetClientSitesByRoleId(siteObj) {
		return this.httpClient.post('/customers/GetClientSitesByRoleId', siteObj, {
			observe: "body"
		});
	};

	saveDeviceOwners(device) {
		return this.httpClient.post('/customers/saveDeviceOwners', device, {
			observe: "body"
		});
	};

	saveDeviceDates(devicetype, deviceIDs, device) {
		return this.httpClient.post('/customers/saveDeviceDates', {devicetype, deviceIDs, device}, {
			observe: "body"
		});
	};

	getActionLogs(options) {
		return this.httpClient.post('/management/getActionLogs', options, {
			observe: "body"
		});
	};
	getGuestUser(guestID = 0){
		return this.httpClient.post('/users/getGuestUser', {guestID}, {
			observe: "body"
		});
	}
	getUsersByRole(role, id){
		return this.httpClient.post('/users/getUsersByRole', {role, id}, {
			observe: "body",
			responseType:"json"
		});
	}
	getUsers(id, isEdit) {
		return this.httpClient.post('/users/get', {id, isEdit}, {
			observe: "body",
			responseType:"json"
		});
	}
	updateUserAccessManagement(id, accessfunctions) {
		return this.httpClient.post('/users/updateUserAccessManagement', {id, accessfunctions}, {
			observe: "body",
			responseType:"text"
		});
	}
	getUsersListing(getBy, options) {
		return this.httpClient.post('/users/getUsersListing', {getBy, options}, {
			observe: "body",
			responseType:"json"
		});
	}
	getBecomeUserToken(userid) {
		return this.httpClient.post('/users/getBecomeUserToken', {userid}, {
			observe: "body",
			responseType:"text"
		});
	}
	ACTresetUserPassword(id) {
		return this.httpClient.post('/users/ACTresetUserPassword', {id}, {
			observe: "body",
			responseType:"text"
		});
	}
	deleteUser(id) {
		return this.httpClient.post('/users/deleteUser', {id}, {
			observe: "body",
			responseType:"text"
		});
	}
	getUserPermissions(id) {
		return this.httpClient.post('/users/getUserPermissions', {id}, {
			observe: "body"
		});
	}
	updateUserPermissions(id, accessObj, tempAccess) {
		return this.httpClient.post('/users/updateUserPermissions', {id, accessObj, tempAccess}, {
			observe: "body",
			responseType:"text"
		});
	}
	getEquipments(id=0) {
		return this.httpClient.post('/management/getEquipments', {id}, {
			observe: "body"
		});
	}
	getEquipmentsDevices(deviceId, linkId, linkType) {
		return this.httpClient.post('/management/getEquipmentsDevices', {deviceId, linkId, linkType}, {
			observe: "body"
		});
	}
	deleteEquipmentDevice(id, childId) {
		return this.httpClient.post('/management/deleteEquipmentDevice', {id, childId}, {
			observe: "body",
			responseType: "text"
		});
	}
	getRejectedDeviceConnections(options) {
		return this.httpClient.post('/management/getRejectedDeviceConnections', {options}, {
			observe: "body"
		});
	}
	deleteRejectionLog(id) {
		return this.httpClient.post('/management/deleteRejectionLog', {id}, {
			observe: "body",
			responseType:"text"
		});
	}
	updateEquipmentDevice(equipment, linkId, linkType) {
		equipment.link_id = linkId;
		equipment.link_type = linkType;
		return this.httpClient.post('/management/updateEquipmentDevice', {equipment}, {
			observe: "body",
			responseType:"text"
		});
	}
	getReplacementHistory(options) {
		return this.httpClient.post('/management/getReplacementHistory', {options}, {
			observe: "body"
		});
	}
	deleteReplacementLogs(ids) {
		return this.httpClient.post('/management/deleteReplacementLogs', {ids}, {
			observe: "body",
			responseType:"text"
		});
	}
	getDevicePO(ids) {
		return this.httpClient.post('/management/getDevicePO', {ids}, {
			observe: "body",
		});
	}
	saveDevicePO(device) {
		return this.httpClient.post('/management/saveDevicePO', {device}, {
			observe: "body",
		});
	}
	autoCorrentDevices(siteId, type, ids) {
		return this.httpClient.post('/management/autoCorrectDevices', {siteId, type, ids}, {
			observe: "body",
		});
	}
	
	forceWebsiteRefresh() {
		return this.httpClient.post('/management/forceWebsiteRefresh', {}, {
			observe: "body"
		});
	}

	getRegistrationReportData() {
		return this.httpClient.post('/management/getRegistrationReportData', {}, {
			observe: "body",
		});
	}

	registrationPurposes(customerId: number, siteId: number) {
		return this.httpClient.post('/reporting/registrationPurposes', {customerId, siteId}, {
			observe: "body",
		});
	}

	generateData(deviceId: string, deviceType: number, from: Date, to: Date, type: string, maxPerDay: number) {
		return this.httpClient.post('/admins/generateData', { deviceId, deviceType, from, to, type, maxPerDay }, {
			observe: "body"
		})
	}
}
