<app-widgets-loader
	key="truck-performance"
	[data]="{
		data: data,
		siteId: siteId,
		customerId: customerId,
		siteName: siteName,
		customerName: customerName,
		siteTruckviewsData: siteTruckviewsData,
		siteTags: siteTags,
		siteTruckviewsTags: siteTruckviewsTags,
		isACTuser: user.isACTuser
	}"
	[passAllDataOnce]="true"
></app-widgets-loader>