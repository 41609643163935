import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';

@Injectable()
export class ChartUtil {
	generateChartData(colArray, _data) {
		var resultData = [];
		if(_data){
			var oldValueArray = [];
			for(var element of _data){
				var objArray = [];
				var x = 0;
				for(var item of colArray){
					switch(item.type){
						case "int":
						if (element[item.col] !== undefined) {
							var n = parseInt(element[item.col]);
							n = _.isNumber(n) ? n : 0;

							objArray.push(n);
							oldValueArray[x] = n;
						} else {
							objArray.push(oldValueArray[x] || 0);
						}
						break;
						case "float":
							if (element[item.col] !== undefined) {
								var n = parseFloat(element[item.col]);
								n = _.isNumber(n) ? n : 0;

								objArray.push(n);
								oldValueArray[x] = n;
							} else {
								objArray.push(oldValueArray[x] || 0);
							}
						break;
						case "date":
							var now = new Date();
							var diff = (now.getHours() - now.getUTCHours()) * 3600 * 1000;
							// shift by diff to make it GMT then shift 12 hours to appear date centeralized in chart
							var d = new Date(element[item.col] - diff + (12*3600*1000));
							if (d !== undefined) {
								objArray.push(d);
							} else {
								objArray.push(new Date());
							}
						break;
						case "timeofday":
							var timeOfDay = [];
							var hours = Math.floor(element[item.col] / (1000 * 60 * 60));
							var mins = Math.floor(element[item.col] / (1000* 60) % 60);
							if (hours >= 24) {
								hours = 23;
								mins = 59;
							}
							timeOfDay.push(hours);
							timeOfDay.push(mins);
							timeOfDay.push(0);
							timeOfDay.push(0);
							objArray.push(timeOfDay);
						break;
						case "passedvalue":
							objArray.push(item.value);
						break;
						default:
							if (element[item.col] !== undefined) {
								objArray.push(element[item.col]);
								oldValueArray[x] = element[item.col];
							} else {
								objArray.push(oldValueArray[x] || "");
							}
					}
					x++;
				}
				resultData.push(objArray);
			}
		}
		return resultData;
	}
	getColor(value, inverse = false) {
		//from 100% to %
		var colors = [
			'#2AFD2F',
			'#81FA30',
			'#AFFD45',
			'#FFFD38',
			'#FED631',
			'#FDA428',
			'#FD8C25',
			'#FC471E',
			'#EA212D',
			'#EA212D',
			'#EA212D'
		];
		value = Math.floor(value / 10);
		if(value > 10)
			value = 10;

		if (inverse) {
			return colors[value];
		}
		return colors[10 - value];
	}
	getChartImageUri(chartObj) {
		var thisChart;
		var image;
		if (typeof chartObj.getChart == "function") {
			thisChart = chartObj.getChart();
		} else if (typeof chartObj.getImageURI == "function") {
			thisChart = chartObj;
		}
		if(thisChart) {
			image = thisChart.getImageURI();
		}
		return image;
	}
}