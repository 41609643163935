import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { UserService } from '../../../../auth/user.service';
import { ChargersDashboardService } from '../../chargers/chargers-dashboard/chargers-dashboard.service';
import { SiteDashboardService } from '../../../site-dashboard/site-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { DevicesDashboardService } from '../../devices/devices-dashboard.service';

@Component({
	selector: 'app-chargers-listing',
	templateUrl: './chargers-listing.component.html',
	styleUrls: ['./chargers-listing.component.css']
})
export class ChargersListingComponent implements OnInit, OnDestroy, OnChanges {

	@Input() currentSite: any = {};
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() currentUser: any = {};
	
	chargersListing: any = {};
	timeoutId;
	hasChargers: boolean = true;
	connectivityStatusList = [];
	productionIps: any[];
	checkedDevices = [];
	

	@Output() chargersListingReady = new EventEmitter<object>();
	@Output() siteTagsChanged = new EventEmitter<object>();
	@Output() deviceTagsChanged = new EventEmitter<object>();
	@Output() refreshFilter = new EventEmitter();
	@Output() deviceIsChecked = new EventEmitter<boolean>();

	constructor(
		public userService: UserService,
		private commonUtil: CommonUtil,
		private chargersDashboard: ChargersDashboardService,
		private siteDashboard: SiteDashboardService,
		public devicesDashboardService: DevicesDashboardService
	) { }

	ngOnInit() {
		this.siteDashboard.setActiveSubTab('listing');
		this.connectivityStatusList = [
			{label: "Charging", value:"charging" },
			{label: "Idle", value:"idle" },
			{label: "Disconnected", value:"disconnected"}
		]
	}

	ngOnChanges() {
		if(Object.keys(this.currentSite).length > 0 && this.customerId && this.customerId)
			this.getChargersListing();
	}

	getChargersListing(isRefresh = false) {

		if(Object.keys(this.chargersListing).length == 0 || isRefresh) {

			var siteid		= this.siteId;
			var customerid	= this.customerId;

			if(siteid && customerid) {
				this.chargersDashboard.getChargersListing(customerid, siteid, isRefresh, {checkPMFault: true}).subscribe((response: any) => {
					this.hasChargers = (Object.keys(response.list).length > 0);

					if(!this.hasChargers) {
						this.chargersListingReady.emit({
							'list': {},
							'hasDevices': false,
							'connectivityStatusList': []
						});
						return;
					}

					var chargersListing = {'4X': [], '6X': [], '12X': []};
					var modelsType		= {'Q4': '4X', 'Q6': '6X', 'Q12': '12X'};

					var result	= _.sortBy(response.list, 'chargerusername');

					var deviceGroups = {};

					let is_site_plc_enabled = response.isSitePLCEnabled || false;

					result.forEach((charger) => {

						charger.model = charger.model || '';
						charger.model = charger.model.trimRight();
						charger.lastconnecttime = new Date(charger.lastconnecttime);
						if (this.checkedDevices.indexOf(charger.id) > -1) {
							charger.selected = true;
						}
						var modeltype = charger.model.split('-')[0];

						deviceGroups[charger.id] = charger.actfirstgroupid;

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						if(charger.actviewconnectfrequency == this.siteDashboard.autoconnectFreq)
							charger.actviewconnectfrequency = 60;

						var disconnectLimit	= 2.5 * parseInt(charger.actviewconnectfrequency);
						var disconnectTime: any	= response.nowTime - (new Date(charger.lastconnecttime).getTime() / 1000); //disconnectTime in seconds

						//More than a week disconnectTime > 7*24*3600
						var chargerStatus = 'disconnected-disabled';
						charger.connectivityStatus = 'disconnected';

						if(this.currentSite.act_intelligent_type != 0 && charger.actviewenable) {

							if(disconnectTime <= disconnectLimit) {

								//Less than 2.5 times of normal connectFrequency
								chargerStatus = 'idle';

								if(charger.LCD.batteryCharging_p == 1) 
									chargerStatus = 'charging';

								charger.connectivityStatus = chargerStatus;

							} else  {
								if (disconnectTime > 7*24*3600)
									chargerStatus = 'disconnected-week'; // more than 1 week (Red)
								else if (disconnectTime > 24*3600)
									chargerStatus = 'disconnected-day'; // between 1 day to 7 (Orange with Red Line)
								else if (disconnectTime > 15*60)
									chargerStatus = 'disconnected'; // 15 min to 24 hours (Blue with Red Line)
								else
									chargerStatus = 'idle' //less than 15 min (Blue)
							}
						}

						let gsePhrase = '';
						if (this.commonUtil.checkChargerGseModel(charger.serialnumber) && modeltype != 'Q4') // GSE chargers cannot be Q4
							gsePhrase = 'gse-';

						var chargerImageClass = 'ch-' + gsePhrase + modeltype + '-' + chargerStatus;
						
						if(['idle', 'charging'].includes(chargerStatus) && charger.pmwarningtype && this.currentUser.isACTuser && !charger.ignoreWarnings) {
							var chargerPMwarning = '';
							if(charger.pmwarningtype == 0xA1)
								chargerPMwarning = 'info';
							else if(charger.pmwarningtype != 0) {
								chargerPMwarning = 'alert';

								if(charger.firmwareversion > 2.17) {
									if((charger.pmwarningtype & 0xC0) == 0xC0)
										charger.extraMsg = {'type': 'Faulted', 'count': (charger.pmwarningtype & 0x0F)};
									else if((charger.pmwarningtype & 0x80) == 0x80)
										charger.extraMsg = {'type': 'Never connected', 'count': (charger.pmwarningtype & 0x0F)};
								}
							}

							chargerImageClass	+= ('-' + chargerPMwarning);
							charger.tab			= 'faults';
						} else {

							charger.timespanText	= this.commonUtil.secondsToElapsedTime(disconnectTime);
							charger.tab				= 'cycles';
						}

						charger.imageclass = chargerImageClass + ' ch-' + modeltype + '-thumbnail';
						chargersListing[modelsType[modeltype]].push(charger);
					});

					this.chargersListing 	= chargersListing;
					this.productionIps		= response.productionIps || [];

					let siteTagsById	= {};
					let deviceTags		= {};

					if(response.tags) {
						siteTagsById	= response.tags.siteTags;
						deviceTags		= response.tags.deviceTags;
						this.deviceTagsChanged.emit([deviceTags, deviceGroups]);
					}

					var initialVal		= 999999;
					var minConnectTime	= initialVal;
					
					var todayTime = +(moment().utc().startOf('day').format("X"));
					
					for(var model in this.chargersListing) {
						
						this.chargersListing[model].forEach((item) => {

							if(item.actviewconnectfrequency < minConnectTime)
								minConnectTime = item.actviewconnectfrequency;
							
							item.isShow = true;

							var tags = deviceTags[item.id];
							item.tags = [];
							for (var i = 0; i < tags.length; i++) {
								if (siteTagsById[tags[i].tagid]) {
									let tmpTag = Object.assign({}, tags[i]);
									item.tags.push(Object.assign(tmpTag, siteTagsById[tags[i].tagid]));
								}
							}

							item.tooltipMessage	= [];
							
							if(item.timespanText && item.actviewenable)
								item.tooltipMessage.push('Last connect: ' + item.timespanText);

							if(item.extraMsg && this.userService.hasAccessFunction('service_page')) {
								if(item.extraMsg.type == 'Faulted') {

									if(item.extraMsg.count == 1)
										item.tooltipMessage.push(item.extraMsg.count + ' PM has Fault(s)');
									else
										item.tooltipMessage.push(item.extraMsg.count + ' PMs have Fault(s)');
								} else if(item.extraMsg.type == 'Never connected') {

									item.tooltipMessage.push(item.extraMsg.count + ' PMs not Detected');
								}
							}

							if(
								is_site_plc_enabled &&
								this.userService.hasAccessFunction('PLC_error_detection') &&
								item.enableplc &&
								this.userService.hasAccessFunction('service_page')
							) {
								var plc_event_time			= +(moment(item.plc_event_time).utc().startOf('day').format("X"));
								var hide_plc_fault_date		= +(moment(item.hide_plc_fault_date).utc().startOf('day').format("X"));
								var last_charge_cycle_time	= +(moment(item.last_charge_cycle_time).utc().startOf('day').format("X"));
								
								if (todayTime > hide_plc_fault_date) {
									// if plc_event_time is 1-1-1970 and last_charge_cycle_time != 1-1-1970 save a fault called no_plc_actvity
									if (plc_event_time < 1*24*60*60 && last_charge_cycle_time > 1*24*60*60) {
										item.tooltipMessage = item.tooltipMessage.concat('No PLC Activity');
									} else if (last_charge_cycle_time > todayTime - (1*7*24*60*60) && plc_event_time < todayTime - (1*8*24*60*60)) {
										// ELSE if last_charge_cycle_time is within last 1 week, and plc_event_time is older than 8 days..save a fault called plc_warning
										item.tooltipMessage = item.tooltipMessage.concat('PLC Warning');
									}
								}
							}

							if(item.error_code && this.currentUser.isACTuser && this.userService.hasAccessFunction('service_page')) {
								item.tooltipMessage.push(this.commonUtil.getChargerErrorText(item.error_code));
							}

							if(this.productionIps.includes(item.ip_address)) {
								item.tooltipMessage.push("Production Line IP");
							}
						});
					}

					if(minConnectTime != initialVal) {

						var checkFreq = Math.ceil(minConnectTime / 2) * 1000;
						
						if(checkFreq < 60000)
							checkFreq = 60000; //At most every 60 seconds

						this.refreshChargersList(checkFreq);
					}

					// site tags
					if (!isRefresh) {
						let siteGroups = response.groups;
						var siteTags = [];
						for (var tagIndex in siteTagsById) {
							var item = response.tags.siteTags[tagIndex];
							item.isActive = false;
							siteTags.push(item);
						}
						this.siteTagsChanged.emit([siteTags, siteGroups]);
					}

					this.chargersListingReady.emit({
						'list': chargersListing,
						'hasDevices': result.length > 0,
						'connectivityStatusList': this.connectivityStatusList
					});
					if(isRefresh)
						this.refreshFilter.emit();
				});
			}
		}
	}

	getBgClass(charger) {
		var className = "";
		if(charger.error_code && this.currentUser.isACTuser && this.userService.hasAccessFunction('service_page'))
			className = 'red-bg';

		if(this.productionIps.includes(charger.ip_address))
			className = 'orange-bg';

		return className;
	}

	deviceChecked(isChecked, id) {
		if (isChecked) {
			this.checkedDevices.push(id);
		} else {
			let index = this.checkedDevices.indexOf(id);
			this.checkedDevices.splice(index, 1);
		}
		this.deviceIsChecked.emit(isChecked);
	}

	refreshChargersList(checkFreq) {	
		this.timeoutId = setTimeout(() => {
			if(Object.keys(this.chargersListing).length > 0)
				this.getChargersListing(true);

		}, checkFreq);
	}

	ngOnDestroy() {
		clearInterval(this.timeoutId);
	}

	setListingObject(data) {
		this.chargersListing = data;
	}
}