import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class NavigationService {
	
	noLoginRoutes = ['/login', '/register', '/forgot-password', '/change-password', '/change-user-password'];
	currentUrl: string = '';

	constructor(
		private router: Router,
		private location: Location,
	) {
	}

	updateNavigationUrl(e) {
		let doChange = this.setPreviousUrl(this.currentUrl);
		if(doChange && this.noLoginRoutes.indexOf(e['url']) == -1)
			this.currentUrl = e['url'];
	}

	setPreviousUrl(url, remove = false) {

		if(remove) {

			sessionStorage.removeItem('previousUrl');
			this.currentUrl = '';
			return false;
		}

		if(this.noLoginRoutes.indexOf(url) == -1 && !url.includes('reset-password')) {

			if(url)
				sessionStorage.setItem('previousUrl', url);

			return true;
		}
		
		return false;
	}

	getPreviousUrl() {
		return sessionStorage.getItem('previousUrl');
	}

	goBack(defaultPage: Array<any> = ['/sites-map']) {
		let previousUrl = this.getPreviousUrl();
		let currentUrl = this.location.path();
		if(previousUrl && previousUrl != currentUrl)
			this.router.navigateByUrl(previousUrl);
		else
			this.router.navigate(defaultPage);
	}
}